<template>
	<div>
		<h3 v-if="deplacement_edit && deplacement_edit.deplacement_id">{{ $t('deplacement.edit_transport') }}</h3>
		<h3 v-else>{{ $t('deplacement.ajout_transport') }}</h3>

		<div class="row">
			<div class="col-sm-4">
				<label>{{ $t('deplacement.depart') }}</label>
				<div v-if="hasFacturationAccess" class="d-flex">
					<b-form-radio v-model="show_tiers_depart" :value="false" class="mr-2 mb-2">{{ $t('deplacement.lieu') }}</b-form-radio>
					<b-form-radio v-model="show_tiers_depart" :value="true" class="mr-2">{{ $t('deplacement.tiers') }}</b-form-radio>
				</div>
			</div>
			<div class="col-sm-8">
				<div class="d-flex">
                    <SearchTiers 
                        v-if="hasFacturationAccess && show_tiers_depart"
                        :tiers_selected.sync="deplacement.deplacement_depart_tiers"
                        :preselected="deplacement.deplacement_depart_tiers"
                        :presearch="presearch_depart_tiers"
                        class="mr-2"
                    />
                    <e-select
                        v-else-if="forceReload"
                        class="mr-2"
                        track-by="lieu_id"
                        v-model="deplacement.deplacement_depart_lieu"
                        :options="all_lieux"
                        :placeholder="$t('mouvement.rechercher_lieu')"
                        :sortable="false"
						label="lieu_label"
                        @input="changeForceReload"
                    >
                        <template slot="singleLabel" slot-scope="{ option }">{{ option.lieu_label }} {{ option.lieu_adresse }} {{ option.lieu_cp }} {{ option.lieu_ville }}</template>
                        <template slot="option" slot-scope="{ option }">{{ option.lieu_label }} {{ option.lieu_adresse }} {{ option.lieu_cp }} {{ option.lieu_ville }}</template>
                    </e-select>
                    <button class="btn btn-secondary" @click="addTierOrLieu('depart')"><font-awesome-icon :icon="['far', 'plus']" /></button>
                </div>
			</div>


			<div class="col-sm-4">
				<label>{{ $t('deplacement.arrive') }}</label>
				<div class="d-flex">
					<b-form-radio v-model="show_tiers_arrive" :value="false" class="mr-2 mb-2">{{ $t('deplacement.lieu') }}</b-form-radio>
					<b-form-radio v-model="show_tiers_arrive" :value="true" class="mr-2">{{ $t('deplacement.tiers') }}</b-form-radio>
				</div>
			</div>
			<div class="col-sm-8">
				<div class="d-flex">
                    <SearchTiers 
                        v-if="hasFacturationAccess && show_tiers_arrive"
                        :tiers_selected.sync="deplacement.deplacement_arrive_tiers"
                        :preselected="deplacement.deplacement_arrive_tiers"
                        :presearch="presearch_arrive_tiers"
                        class="mr-2"
                    />
                    <e-select
                        v-else-if="forceReload"
                        class="mr-2"
                        track-by="lieu_id"
                        v-model="deplacement.deplacement_arrive_lieu"
                        :options="all_lieux"
                        :placeholder="$t('mouvement.rechercher_lieu')"
                        :sortable="false"
						label="lieu_label"
                        @input="changeForceReload"
                    >
                        <template slot="singleLabel" slot-scope="{ option }">{{ option.lieu_label }} {{ option.lieu_adresse }} {{ option.lieu_cp }} {{ option.lieu_ville }}</template>
                        <template slot="option" slot-scope="{ option }">{{ option.lieu_label }} {{ option.lieu_adresse }} {{ option.lieu_cp }} {{ option.lieu_ville }}</template>
                    </e-select>
                    <button class="btn btn-secondary" @click="addTierOrLieu('arrive')"><font-awesome-icon :icon="['far', 'plus']" /></button>
                </div>
			</div>

			<div class="col-sm-4">
				<label>{{ $t('deplacement.horse') }}</label>
			</div>
			<div class="col-sm-8">
				<SearchHorse :horse_selected.sync="horse_selected" />
				<button 
					v-for="(horse, index) in horses"
					:key="'horse_'+index"
					@click="deleteHorse(horse)"
					class="btn btn-sm btn-primary rounded-pill mt-1 mr-2"
				>
					{{ horse.horse_nom }} 
					<font-awesome-icon :icon="['fal', 'times']" />
				</button>
			</div>

			<div class="col-sm-4 mt-2">
				<label>{{ $t('deplacement.date') }}</label>
			</div>
			<div class="col-sm-8 mt-2">
                <div class="row">
                    <e-datepicker class="col" v-model="deplacement.deplacement_date" mode="date"></e-datepicker>
                    <e-datepicker class="col" v-model="deplacement.deplacement_time" mode="time"></e-datepicker>
                </div>
            </div>

			<div class="col-sm-4 mt-2">
				<label>{{ $t('deplacement.duree_trajet') }}</label><br>
				<i>{{ $t('deplacement.exemple_duree') }}</i>
			</div>
			<div class="col-sm-8 mt-2">
				<b-input type="text" class="form-control" v-model="deplacement.deplacement_duree" :class="{ 'is-invalid': !errorDuration }"/>
            </div>

			<div class="col-sm-4 mt-2">
				<label>{{ $t('deplacement.vehicule') }}</label>
			</div>
			<div class="col-sm-8 mt-2">
				<div class="d-flex">
	                <e-select
                        v-if="forceReload"
	                    class="mr-2"
	                    track-by="vehicule_id"
	                    v-model="deplacement.deplacement_vehicule"
	                    :options="all_vehicules"
	                    :sortable="false"
						label="vehicule_name"
                        @input="changeForceReload"
	                >
	                    <template slot="singleLabel" slot-scope="{ option }">{{ option.vehicule_name }}</template>
	                    <template slot="option" slot-scope="{ option }">{{ option.vehicule_name }}</template>
	                </e-select>
	                <button class="btn btn-secondary" @click="openAjoutVehicule"><font-awesome-icon :icon="['far', 'plus']" /></button>
	            </div>
            </div>

			<div class="col-sm-4 mt-2">
				<label>{{ $t('deplacement.chauffeur') }}</label>
			</div>
			<div class="col-sm-8 mt-2">
				<div class="d-flex">
					<SearchContact
                        :contact_selected.sync="deplacement.deplacement_chauffeur"
                        :preselected="deplacement.deplacement_chauffeur"
                        :presearch="presearch_contact"
                        class="mr-2"
                    />
					<button class="btn btn-secondary" @click="openAjoutContact"><font-awesome-icon :icon="['far', 'plus']" /></button>
				</div>
			</div>

			<div class="col-sm-4 mt-2">
				<label>{{ $t('deplacement.motif') }}</label>
			</div>
			<div class="col-sm-8 mt-2">
				<b-input type="text" class="form-control" v-model="deplacement.deplacement_motif"/>
            </div>

			<div class="d-none col-sm-4 mt-2">
				<label>{{ $t('deplacement.create_mouvement') }}</label>
			</div>
			<div class="d-none col-sm-8 mt-2">
				<b-form-checkbox v-model="mouvement"/>
            </div>

			<div class="col-sm-4 mt-2">
				<label>{{ $t('deplacement.create_retour') }}</label>
			</div>
			<div class="col-sm-8 mt-2">
				<b-form-checkbox v-model="retour"/>
            </div>
		</div>

		<div class="text-center">
			<b-button v-if="deplacement_edit && deplacement_edit.deplacement_id"  pill variant="primary" class="mt-3" @click.prevent="saveForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'edit']" /> {{ $t("global.modifier") }}</b-button>
            <b-button v-else  pill variant="primary" class="mt-3" @click.prevent="saveForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['far', 'plus']" /> {{ $t("global.ajouter") }}</b-button>
        </div>
	</div>
</template>

<script type="text/javascript">
    import Shutter from '@/mixins/Shutter.js'
	import Deplacement from "@/mixins/Deplacement.js"
	import Lieux from '@/mixins/Lieux'
	import ShutterDeplacement from '@/mixins/shutters-manager/Deplacement.js'

	export default {
		name: 'DeplacementForm',
		mixins: [Shutter, Deplacement, Lieux, ShutterDeplacement],
		props: ['deplacement_edit', 'suggestions', 'date'],
		data () {
			return {
				deplacement: {
					deplacement_date: new Date(),
					deplacement_time: new Date(),
					deplacement_motif: '',
					deplacement_duree: '',
					deplacement_depart_lieu: null,
					deplacement_depart_tiers: null,
					deplacement_arrive_lieu: null,
					deplacement_arrive_tiers: null,
					deplacement_vehicule: null,
					deplacement_chauffeur: null,
				},
				horses: [],
				horse_selected: null,
				show_tiers_depart: false,
				show_tiers_arrive: false,
				all_lieux: [],
				all_vehicules: [],
				processing: false,
				mouvement: true,
				presearch_depart_tiers: null,
				presearch_arrive_tiers: null,
				presearch_contact: null,
				forceReload: true,
				retour: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
	            this.all_lieux = await this.getLieux(false)
	            const all_vehicules = await this.getAllVehicules()
	            this.all_vehicules = all_vehicules.filter(v => !v.vehicule_archive)

	            if(this.deplacement_edit){
	            	const date = new Date(this.deplacement_edit.deplacement_date + " " + this.deplacement_edit.deplacement_time)

		            this.deplacement = {
		            	deplacement_date: date,
						deplacement_time: date,
						deplacement_motif: this.deplacement_edit.deplacement_motif,
						deplacement_duree: this.deplacement_edit.deplacement_duree,
		            }

		            if(this.deplacement_edit.deplacement_depart_lieu) {
		            	this.deplacement.deplacement_depart_lieu = this.all_lieux.find(lieu => lieu.lieu_id == this.deplacement_edit.deplacement_depart_lieu)
		            }

		            if(this.deplacement_edit.deplacement_arrive_lieu) {
		            	this.deplacement.deplacement_arrive_lieu = this.all_lieux.find(lieu => lieu.lieu_id == this.deplacement_edit.deplacement_arrive_lieu)
		            }

		            if(this.deplacement_edit.deplacement_depart_tiers) {
		            	this.deplacement.deplacement_depart_tiers = this.deplacement_edit.depart_tiers
		            	this.show_tiers_depart = true
		            }

		            if(this.deplacement_edit.deplacement_arrive_tiers) {
		            	this.deplacement.deplacement_arrive_tiers = this.deplacement_edit.arrive_tiers
		            	this.show_tiers_arrive = true
		            }

		            if(this.deplacement_edit.deplacement_chauffeur) {
		            	this.deplacement.deplacement_chauffeur = this.deplacement_edit.chauffeur
		            }

		            if(this.deplacement_edit.deplacement_vehicule) {
		            	this.deplacement.deplacement_vehicule = this.all_vehicules.find(vehicule => vehicule.vehicule_id == this.deplacement_edit.deplacement_vehicule)
		            }

		            this.horses = this.deplacement_edit.horse
		        }

		        if(this.suggestions) {
		        	this.horses = this.suggestions.map(sug => sug.horse)
		        	this.deplacement.deplacement_arrive_lieu = this.all_lieux.find(lieu => lieu.lieu_id == this.suggestions[0].lieu.lieu_id)
		        	this.deplacement.deplacement_date = this.date
		        }
			},

			deleteHorse(horse) {
				const index = this.horses.indexOf(horse);
				if (index > -1) {
					this.horses.splice(index, 1)
				}
			},

	        addTierOrLieu(type) {
	            if(type == "depart" && this.show_tiers_depart) {
	                this.openAddTierDepart()
	            }
	            else if(type == "arrive" && this.show_tiers_arrive) {
	                this.openAddTierArrive()
	            }
	            else {
	                this.openAjoutLieu()
	            }
	        },

	        async lieuFormSubmit() {
				this.successToast('toast.info_save_succes')
	            this.all_lieux = await this.getLieux(false)
	        },

	        setTierDepart(a) {
	            this.presearch_depart_tiers = a
	        },

	        setTierArrive(a) {
	            this.presearch_arrive_tiers = a
	        },

	        contactFormSubmit(a) {
	            this.presearch_contact = a
	        },

	        async vehiculeSubmit(vehicule_id) {
	            this.all_vehicules = await this.getAllVehicules()
	            this.deplacement.deplacement_vehicule = this.all_vehicules.find(vehicule => vehicule.vehicule_id == vehicule_id)
	        },

	        async saveForm() {
	        	this.processing = true

	        	if(!this.errorDuration) {
	        		return false
	        	}

	        	let params = {
					deplacement_date: this.deplacement.deplacement_date,
					deplacement_time: this.deplacement.deplacement_time.toTimeString(),
					deplacement_motif: this.deplacement.deplacement_motif,
					deplacement_duree: this.deplacement.deplacement_duree,
					deplacement_depart_lieu: null,
					deplacement_depart_tiers: null,
					deplacement_arrive_lieu: null,
					deplacement_arrive_tiers: null,
					deplacement_vehicule: this.deplacement.deplacement_vehicule ? this.deplacement.deplacement_vehicule.vehicule_id : null,
					deplacement_chauffeur: this.deplacement.deplacement_chauffeur ? this.deplacement.deplacement_chauffeur.contact_id : null,
					horses: this.horses.map(horse => horse.horse_id)
	        	}

				await this.$sync.force(true)

	        	if(this.show_tiers_depart) {
	        		params.deplacement_depart_tiers = this.deplacement.deplacement_depart_tiers ? this.$sync.replaceWithReplicated('tiers', this.deplacement.deplacement_depart_tiers.tiers_id) : null
	        	}
	        	else {
	        		params.deplacement_depart_lieu = this.deplacement.deplacement_depart_lieu ? this.$sync.replaceWithReplicated('lieu', this.deplacement.deplacement_depart_lieu.lieu_id) : null
	        	}

	        	if(this.show_tiers_arrive) {
	        		params.deplacement_arrive_tiers = this.deplacement.deplacement_arrive_tiers ? this.$sync.replaceWithReplicated('tiers', this.deplacement.deplacement_arrive_tiers.tiers_id) : null
	        	}
	        	else {
	        		params.deplacement_arrive_lieu = this.deplacement.deplacement_arrive_lieu ? this.$sync.replaceWithReplicated('lieu', this.deplacement.deplacement_arrive_lieu.lieu_id) : null
	        	}

	        	const mouvement = this.mouvement ? 1 : 0;

	        	if(this.deplacement_edit && this.deplacement_edit.deplacement_id) {
	        		await this.editDeplacement(this.deplacement_edit.deplacement_id, params, mouvement)
	        	}
	        	else {
	        		await this.createDeplacement(params, mouvement)
	        	}
	        	this.processing = false

	        	this.ok()
	        	this.shutterPanel().close('deplacement-form')
	        	this.shutterPanel().close('lieu-add-form')
				this.shutterPanel().close('ajout-tiers-depart')
				this.shutterPanel().close('ajout-tiers-arrive')
				this.shutterPanel().close('contact-add-form')
				this.shutterPanel().close('ajout-vehicule')
				this.successToast('toast.info_save_succes')

				if(this.retour) {
					const depart_tiers = params.deplacement_depart_tiers
					const depart_lieu = params.deplacement_depart_lieu

					const arrive_tiers = params.deplacement_arrive_tiers
					const arrive_lieu = params.deplacement_arrive_lieu

		        	let params_retour = {
						deplacement_date: this.deplacement.deplacement_date.toDateInputValue(),
						deplacement_time: this.deplacement.deplacement_time.toTimeString(),
						deplacement_motif: this.deplacement.deplacement_motif,
						deplacement_duree: this.deplacement.deplacement_duree,
						deplacement_depart_lieu: arrive_lieu,
						deplacement_depart_tiers: arrive_tiers,
						deplacement_arrive_lieu: depart_lieu,
						deplacement_arrive_tiers: depart_tiers,
						deplacement_vehicule: this.deplacement.deplacement_vehicule ? this.deplacement.deplacement_vehicule.vehicule_id : null,
						deplacement_chauffeur: this.deplacement.deplacement_chauffeur,
						chauffeur: this.deplacement.deplacement_chauffeur,
						horse: this.horses
		        	}

					this.openEditDeplacement(params_retour)
				}
	        },

	        changeForceReload() {
	        	this.forceReload = false
	        	this.$nextTick(() => {
	        		this.forceReload = true
	        	})
	        }
		},
		
	    computed: {
	        hasFacturationAccess() {
	            return this.$store.state.userAccess.hasFacturationAccess
	        },

	        errorDuration() {
	        	if(this.deplacement.deplacement_duree == '') {
	        		return true
	        	}
	        	return /((\d+)h (\d+)(m))|((\d+)h|(\d+)m)$/.test(this.deplacement.deplacement_duree)
	        }
	    },

		watch: {
			horse_selected(val) {
				if(val) {
					this.horses.push(val)
					this.horse_selected = null
				}
			}
		},

		components: {
        	SearchTiers : () => import('@/components/Contract/SearchTiers'),
			SearchHorse : () => import('@/components/Contract/SearchHorse'),
			SearchContact : () => import('@/components/Contract/SearchContact')
		}
	}

</script>